'use client'

import { useEffect } from 'react'

import { Dialog, Text, Image, Icon, ColorCode, Cell, Button, Spacer } from '@vinted/web-ui'
import { DashedCheckCircle24, Money24, ElectronicsBadge24 } from '@vinted/monochrome-icons'

import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'

import FaqEntryUrl from 'components/FaqEntryUrl'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { Screen } from 'constants/tracking/screens'
import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  screen: Screen
  show: boolean
  onClose: () => void
}

const ElectronicsVerificationSellerModal = ({ show, screen, onClose }: Props) => {
  const translate = useTranslate('item.offline_verification.electronics_seller_modal')
  const { track } = useTracking()
  const asset = useAsset('/assets/electronics-verification')

  function handleLearnMoreClick() {
    track(clickEvent({ screen, target: ClickableElement.ElectronicsVerificationLearnMore }))
  }

  function handleClose() {
    track(clickEvent({ screen, target: ClickableElement.ElectronicsVerificationGotIt }))
    onClose()
  }

  useEffect(() => {
    if (!show) return

    track(
      viewEvent({
        screen,
        target: ViewableElement.ElectronicsVerificationSellerModal,
      }),
    )
  }, [track, show, screen])

  return (
    <Dialog show={show} testId="electronics-verification-seller-modal" hasScrollableContent>
      <div className="u-fill-width">
        <div className="u-overflow-auto">
          <div className="u-flexbox u-justify-content-center">
            <Image
              src={asset('seller_illustration_torn.png')}
              alt={translate('a11y.illustration_alt')}
              scaling={Image.Scaling.Contain}
            />
          </div>

          <Cell>
            <Text text={translate('title')} type={Text.Type.Heading} width={Text.Width.Parent} />
          </Cell>

          <Cell
            testId="electronics-verification-seller-modal-cell-1"
            prefix={<Icon name={DashedCheckCircle24} color={ColorCode.GreyscaleLevel4} />}
            title={translate('cell_1.title')}
            body={
              <>
                {translate('cell_1.body')}
                <FaqEntryUrl
                  type={FaqEntryType.ElectronicsVerificationSeller}
                  accessChannel={AccessChannel.ProductLink}
                  render={url => (
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleLearnMoreClick}
                      data-testid="electronics-verification-seller-modal-learn-more-action"
                    >
                      <Text
                        clickable
                        theme="inverse"
                        width={Text.Width.Parent}
                        text={translate('cell_1.action')}
                      />
                    </a>
                  )}
                />
              </>
            }
          />

          <Cell
            testId="electronics-verification-seller-modal-cell-2"
            prefix={<Icon name={ElectronicsBadge24} color={ColorCode.GreyscaleLevel4} />}
            title={translate('cell_2.title')}
            body={translate('cell_2.body')}
          />

          <Cell
            testId="electronics-verification-seller-modal-cell-3"
            prefix={<Icon name={Money24} color={ColorCode.GreyscaleLevel4} />}
            title={translate('cell_3.title')}
            body={translate('cell_3.body')}
          />
        </div>

        <Cell>
          <Button
            text={translate('actions.close')}
            styling={Button.Styling.Filled}
            onClick={handleClose}
            testId="electronics-verification-seller-modal-close-button"
          />
        </Cell>
        <Spacer size={Spacer.Size.Large} />
      </div>
    </Dialog>
  )
}

export default ElectronicsVerificationSellerModal
